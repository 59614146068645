import React from 'react';
import styled, { css } from 'styled-components';
import tachyons from 'styled-components-tachyons';

const HeroStyled = styled.div`
  ${props =>
    props.home &&
    css`
      height: calc(100vh - 88px);
      min-height: 300px;

      @media (min-height: 600px) and (min-width: 600px) {
        height: calc(100vh - 100px);
      }
    `} ${props =>
  props.center &&
  css`
    display: flex;
    align-items: center;
    justify-content: center;
  `} ${props =>
  props.signup &&
  css`
    height: calc(100vh - 100px);
    min-height: 300px;
  `}
  ${tachyons};
`;

const Hero = props => <HeroStyled {...props}>{props.children}</HeroStyled>;

export default Hero;
