import React, { Component } from 'react'
import { A } from '../../components/Type'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import Section from '../../components/Section'
import Wrapper from '../../components/Wrapper'

import Type, {
  Span,
  Subtitle,
  P,
  BulletList,
  ListItem,
} from '../../components/Type'

import { injectIntl, defineMessages } from 'gatsby-plugin-intl'
import { track as trackEvent } from '../../global/analytics'
import eventConstants from '../../global/eventConstants'

export const messageDescriptors = defineMessages({
  title: {
    id: 'pages.legal.terms.title',
    defaultMessage: 'Terms & Conditions',
  },

  subtitle1: {
    id: 'pages.legal.terms.subtitle1',
    defaultMessage: 'Notice Regarding Dispute Resolution',
  },
  subtitle2: {
    id: 'pages.legal.terms.subtitle2',
    defaultMessage: 'Changes to the Terms of Service',
  },
  subtitle3: {
    id: 'pages.legal.terms.subtitle3',
    defaultMessage: 'Accessing the Service and Account Security',
  },
  subtitle4: {
    id: 'pages.legal.terms.subtitle4',
    defaultMessage: 'Intellectual Property Rights',
  },
  subtitle5: {
    id: 'pages.legal.terms.subtitle5',
    defaultMessage: 'Trademarks',
  },
  subtitle6: {
    id: 'pages.legal.terms.subtitle6',
    defaultMessage: 'Prohibited Uses',
  },
  subtitle7: {
    id: 'pages.legal.terms.subtitle7',
    defaultMessage: 'Reliance on Information Posted',
  },
  subtitle8: {
    id: 'pages.legal.terms.subtitle8',
    defaultMessage: 'Changes to the Service',
  },
  subtitle9: {
    id: 'pages.legal.terms.subtitle9',
    defaultMessage: 'Information About You and Your Visits to the Service',
  },
  subtitle10: {
    id: 'pages.legal.terms.subtitle10',
    defaultMessage: 'Linking to the Service',
  },

  subtitle11: {
    id: 'pages.legal.terms.subtitle11',
    defaultMessage: 'Links from the Service',
  },
  subtitle12: {
    id: 'pages.legal.terms.subtitle12',
    defaultMessage: 'Disclaimer of Warranties',
  },
  subtitle13: {
    id: 'pages.legal.terms.subtitle13',
    defaultMessage: 'Limitation on Liability',
  },
  subtitle14: {
    id: 'pages.legal.terms.subtitle14',
    defaultMessage: 'Indemnification',
  },
  subtitle15: {
    id: 'pages.legal.terms.subtitle15',
    defaultMessage: 'Limitation on Time to File Claims',
  },
  subtitle16: {
    id: 'pages.legal.terms.subtitle16',
    defaultMessage: 'Legal Disputes and Arbitration Agreement',
  },
  subtitle17: {
    id: 'pages.legal.terms.subtitle17',
    defaultMessage: 'Waiver and Severability',
  },
  subtitle18: {
    id: 'pages.legal.terms.subtitle18',
    defaultMessage: 'Entire Agreement',
  },
  subtitle19: {
    id: 'pages.legal.terms.subtitle19',
    defaultMessage: 'Your Comments and Concerns',
  },

  list1Item1: {
    id: 'pages.legal.terms.list1Item1',
    defaultMessage: 'Store copies of such materials temporarily in RAM.',
  },
  list1Item2: {
    id: 'pages.legal.terms.list1Item2',
    defaultMessage:
      'Store files that are automatically cached by your Web browser for display enhancement purposes.',
  },
  list1Item3: {
    id: 'pages.legal.terms.list1Item3',
    defaultMessage:
      'Print a reasonable number of pages of the Service for a permitted use.',
  },
  list2Item1: {
    id: 'pages.legal.terms.list2Item1',
    defaultMessage: 'Modify copies of any materials from our Service.',
  },
  list2Item2: {
    id: 'pages.legal.terms.list2Item2',
    defaultMessage:
      'Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from our Service.',
  },

  list3Item1: {
    id: 'pages.legal.terms.list3Item1',
    defaultMessage: `In any way that violates any applicable federal, state, local and
              international law or regulation (including, without limitation,
              any laws regarding the export of data or software to and from the
              US or other countries).`,
  },
  list3Item2: {
    id: 'pages.legal.terms.list3Item2',
    defaultMessage: `To engage in any other conduct that restricts or inhibits anyone's
              use or enjoyment of the Service, or which, as determined by us,
              may harm the us or users of the Service or expose them to
              liability.`,
  },
  list4Item1: {
    id: 'pages.legal.terms.list4Item1',
    defaultMessage: `Use the Service in any manner that could disable, overburden,
              damage, or impair the site or interfere with any other party's use
              of the Service, including their ability to engage in real time
              activities through the Service.`,
  },
  list4Item2: {
    id: 'pages.legal.terms.list4Item2',
    defaultMessage: `Use any robot, spider or other automatic device, process or means
              to access the Service for any purpose, including to monitor or
              copy any of the material on the Service.`,
  },
  list4Item3: {
    id: 'pages.legal.terms.list4Item3',
    defaultMessage: `Use any manual process to monitor or copy any of the material on
              the Service or for any other unauthorized purpose without our
              prior written consent.`,
  },
  list4Item4: {
    id: 'pages.legal.terms.list4Item4',
    defaultMessage:
      'Use any device, software or routine that interferes with the proper working of the Service.',
  },
  list4Item5: {
    id: 'pages.legal.terms.list4Item5',
    defaultMessage:
      'Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.',
  },
  list4Item6: {
    id: 'pages.legal.terms.list4Item6',
    defaultMessage: `Attempt to gain unauthorized access to, interfere with, damage or
              disrupt any parts of the Service, the server on which the Service
              is stored, or any server, computer or database connected to the
              Service.`,
  },
  list4Item7: {
    id: 'pages.legal.terms.list4Item7',
    defaultMessage:
      'Attack the Service via a denial-of-service attack or a distributed denial-of-service attack.',
  },
  list4Item8: {
    id: 'pages.legal.terms.list4Item8',
    defaultMessage:
      'Otherwise attempt to interfere with the proper working of the Service.',
  },

  paragraph1: {
    id: 'pages.legal.terms.paragraph1',
    defaultMessage: 'Effective as of October 18, 2021',
  },
  paragraph2P1: {
    id: 'pages.legal.terms.paragraph2',
    defaultMessage: `This Terms of Service represents an agreement between you, 
    Nielsen Consumer LLC and its group companies (“`,
  },
  paragraph2P2: {
    id: 'pages.legal.terms.paragraph2',
    defaultMessage: `”, “we” or “us”). 
    Together with our privacy policy, this Terms of Service (collectively, these “Terms”) 
    apply to your use of the `,
  },
  paragraph2P3: {
    id: 'pages.legal.terms.paragraph2P3',
    defaultMessage: ` website, apps, or other consumer-facing services (the “Service”).`,
  },
  unrollMeLabel: {
    id: 'pages.legal.terms.unrollMeLabel',
    defaultMessage: `Unroll.Me`,
  },
  paragraph3: {
    id: 'pages.legal.terms.paragraph3',
    defaultMessage: `These Terms of Service contain provisions that govern how claims you
            and we may have against each other are resolved, including an
            agreement and obligation to arbitrate disputes (see “Legal Disputes
            and Arbitration Agreement” below). Subject to limited exceptions,
            these Terms of Service require you to submit claims you have against
            us to binding arbitration, unless you opt-out (see “30 Day Right to
            Opt-Out” below). Unless you opt-out of arbitration, you will only be
            permitted to: (a) pursue claims against us on an individual basis,
            not as part of any class or representative action or proceeding and
            (b) seek relief (including monetary, injunctive, and declaratory
            relief) on an individual basis.`,
  },

  paragraph4: {
    id: 'pages.legal.terms.paragraph4',
    defaultMessage: `We may revise and update the Terms from time to time in our sole
            discretion. Accordingly, you may be asked to assent to new or
            different terms in the future as a condition of using the Service.`,
  },
  paragraph5: {
    id: 'pages.legal.terms.paragraph5',
    defaultMessage: 'You must be 18 years or older to use this Service.',
  },
  paragraph6: {
    id: 'pages.legal.terms.paragraph6',
    defaultMessage:
      'We reserve the right to withdraw or amend this Service in our sole discretion without notice.',
  },
  paragraph7: {
    id: 'pages.legal.terms.paragraph7',
    defaultMessage: `We will not be liable if for any reason all or any part of the
            Service is unavailable at any time or for any period. From time to
            time, we may restrict access to some or all parts of the Service, to
            users, including registered users.`,
  },
  paragraph8: {
    id: 'pages.legal.terms.paragraph8',
    defaultMessage: `You are responsible for ensuring that all persons who access the
            Service through devices that you control are aware of these Terms,
            and that they agree to and comply with them.`,
  },
  paragraph9: {
    id: 'pages.legal.terms.paragraph9',
    defaultMessage: `To access the Service, you may be asked to provide certain
            registration details or other information. It is a condition of your
            use of the Service that all the information you provide on the
            Service is correct, current and complete.`,
  },
  paragraph10: {
    id: 'pages.legal.terms.paragraph10',
    defaultMessage: `If you choose, or you are provided with, a user name, password or
            any other piece of information as part of our security procedures,
            you must treat such information as confidential, and you must not
            disclose it to any third party. You agree to immediately notify us
            of any unauthorized use of your user name or password or any other
            breach of security. You also agree to ensure that you exit from your
            account at the end of each session. You should use particular
            caution when accessing your account from a public or shared computer
            so that others are not able to view or record your password or other
            personal information.`,
  },
  paragraph11: {
    id: 'pages.legal.terms.paragraph11',
    defaultMessage: `We have the right to disable any user name or password, whether
            chosen by you or provided by us, at any time in our sole discretion
            for any or no reason, including, if in our opinion, you have failed
            to comply with any provision of these Terms.`,
  },
  paragraph12: {
    id: 'pages.legal.terms.paragraph12',
    defaultMessage: `The Service and its content, features and functionality (including
            but not limited to all information, software, text, displays,
            images, video and audio, and the design, selection and arrangement
            thereof), are owned by Unroll.me, its licensors or other providers
            of such material and are protected by United States and
            international copyright, trademark, patent, trade secret and other
            intellectual property or proprietary rights laws.`,
  },
  paragraph13: {
    id: 'pages.legal.terms.paragraph13',
    defaultMessage: `You are permitted to use the Service for non-commercial use only.
            You must not copy, modify, create derivative works of, publicly
            display, publicly perform, republish, download, store or transmit
            any of the material on our Service, except to:`,
  },

  paragraph14: {
    id: 'pages.legal.terms.paragraph14',
    defaultMessage: 'You must not:',
  },
  paragraph15: {
    id: 'pages.legal.terms.paragraph15',
    defaultMessage: `You must not reproduce, sell or exploit for any commercial purposes
            any part of the Service, access to the Service or use of the Service
            or any services or materials available through the Service.`,
  },
  paragraph16: {
    id: 'pages.legal.terms.paragraph16',
    defaultMessage: `If you print, copy, modify, download or otherwise use any part of
            the Service in breach of the Terms, your right to use the Service
            will cease immediately and you must, at our option, return or
            destroy any copies of the materials you have made. No right, title
            or interest in or to the Service or any content on it is transferred
            to you, and all rights not expressly granted are reserved by us. Any
            use of the Service not expressly permitted by these Terms is a
            breach of these Terms and may violate copyright, trademark and other
            laws.`,
  },
  paragraph17: {
    id: 'pages.legal.terms.paragraph17',
    defaultMessage: `The Unroll.me name, and all related names, logos, product and
            service names, designs and slogans are trademarks of Unroll.me or
            its affiliates or licensors. You must not use such marks without our
            prior written permission. All other names, brands and marks are used
            for identification purposes only and are the trademarks of their
            respective owners.`,
  },
  paragraph18: {
    id: 'pages.legal.terms.paragraph18',
    defaultMessage:
      'You may use the Service only for lawful purposes and in accordance with these Terms. You agree not to use the Service:',
  },
  paragraph19: {
    id: 'pages.legal.terms.paragraph19',
    defaultMessage: 'Additionally, you agree not to:',
  },
  paragraph20: {
    id: 'pages.legal.terms.paragraph20',
    defaultMessage: `The information presented on or through the Service is made
            available solely for general information purposes. We do not warrant
            the accuracy, completeness or usefulness of this information. Any
            reliance you place on such information is strictly at your own risk.
            We disclaim all liability and responsibility arising from any
            reliance placed on such materials by you or any other visitor to the
            Service, or by anyone who may be informed of any of its contents.`,
  },
  paragraph21: {
    id: 'pages.legal.terms.paragraph21',
    defaultMessage: `We may update the Service from time to time, but its content is not
            necessarily complete or up-to-date. Any of the material on the
            Service may be out of date at any given time, and we are under no
            obligation to update such material. We may change the Service at any
            time with or without notice. We may suspend access to the Service or
            terminate it indefinitely.`,
  },

  paragraph22: {
    id: 'pages.legal.terms.paragraph22',
    defaultMessage: `All information we collect on this Service is subject to our privacy
            policy. By using the Service, you consent to all actions taken by us
            with respect to your information in compliance with the privacy
            policy. You represent and warrant that all data provided by you is
            accurate.`,
  },
  paragraph23: {
    id: 'pages.legal.terms.paragraph23',
    defaultMessage: `You may link to our homepage, provided you do so in a way that is
            fair and legal and does not damage our reputation or take advantage
            of it, but you must not establish a link in such a way as to suggest
            any form of association, approval or endorsement on our part where
            none exists.`,
  },
  paragraph24: {
    id: 'pages.legal.terms.paragraph24',
    defaultMessage:
      'You must not establish a link from any Service that is not owned by you.',
  },
  paragraph25: {
    id: 'pages.legal.terms.paragraph25',
    defaultMessage:
      'The Service must not be framed on any other site, nor may you create a link to any part of the Service other than the homepage.',
  },
  paragraph26: {
    id: 'pages.legal.terms.paragraph26',
    defaultMessage: `You agree to cooperate with us in causing any unauthorized framing
            or linking immediately to cease. We reserve the right to withdraw
            linking permission without notice.`,
  },
  paragraph27: {
    id: 'pages.legal.terms.paragraph27',
    defaultMessage: `If the Service contains links to other sites and resources provided
            by third parties, these links are provided for your convenience
            only. We have no control over the contents of the sites or resources
            accessible from these links and accept no responsibility for them or
            for any loss or damage that may arise from your use of them. If you
            decide to access any of the third-party services linked to this
            Service, you do so entirely at your own risk and subject to the
            terms and conditions of use for such Services.`,
  },
  paragraph28: {
    id: 'pages.legal.terms.paragraph28',
    defaultMessage: `You understand that we cannot and do not guarantee or warrant that
            files available for downloading from the internet or the Service
            will be free of viruses or other destructive code. You are
            responsible for implementing sufficient procedures and checkpoints
            to satisfy your particular requirements for anti-virus protection
            and accuracy of data input and output, and for maintaining a means
            external to our site for any reconstruction of any lost data.`,
  },
  paragraph29: {
    id: 'pages.legal.terms.paragraph29',
    defaultMessage: `WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED
            DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL
            MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS,
            DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SERVICE OR
            ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE OR TO YOUR
            DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY SERVICE LINKED
            TO IT.`,
  },
  paragraph30: {
    id: 'pages.legal.terms.paragraph30',
    defaultMessage: `YOUR USE OF THE SERVICE, ITS CONTENT AND ANY SERVICES OR ITEMS
            OBTAINED THROUGH THE SERVICE IS AT YOUR OWN RISK. THE SERVICE, ITS
            CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE ARE
            PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY
            WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE
            COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY
            WARRANTY OR REPRESENTATION WITH RESPECT TO THE FOREGOING, NEITHER
            THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR
            WARRANTS THAT THE SERVICE, ITS CONTENT OR ANY SERVICES OR ITEMS
            OBTAINED THROUGH THE SERVICE WILL BE ACCURATE, RELIABLE, ERROR-FREE
            OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR
            THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER
            HARMFUL COMPONENTS OR THAT THE SERVICE OR ANY SERVICES OR ITEMS
            OBTAINED THROUGH THE SERVICE WILL OTHERWISE MEET YOUR NEEDS OR
            EXPECTATIONS.`,
  },
  paragraph31: {
    id: 'pages.legal.terms.paragraph31',
    defaultMessage: `THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, EITHER
            EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT
            LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND
            FITNESS FOR PARTICULAR PURPOSE.`,
  },

  paragraph32: {
    id: 'pages.legal.terms.paragraph32',
    defaultMessage: `THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
            EXCLUDED OR LIMITED UNDER APPLICABLE LAW.`,
  },
  paragraph33: {
    id: 'pages.legal.terms.paragraph33',
    defaultMessage: `IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS,
            SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE
            LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT
            OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SERVICE,
            ANY SERVICES LINKED TO IT, ANY CONTENT ON THE SERVICE OR SUCH OTHER
            SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE OR
            SUCH OTHER SERVICES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
            INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT
            LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS,
            LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED
            SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER
            CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR
            OTHERWISE, EVEN IF FORESEEABLE.`,
  },
  paragraph34: {
    id: 'pages.legal.terms.paragraph34',
    defaultMessage:
      'THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.',
  },
  paragraph35: {
    id: 'pages.legal.terms.paragraph35',
    defaultMessage: `You agree to defend, indemnify and hold harmless the Company, its
            affiliates and licensors and their respective officers, directors,
            employees, contractors, agents, licensors and suppliers from and
            against any claims, liabilities, damages, judgments, awards, losses,
            costs, expenses or fees (including reasonable attorneys' fees)
            resulting from your violation of these Terms of Service or your use
            of the Service, including, without limitation, any use of the
            Service's content, services and products other than as expressly
            authorized in these Terms of Service or your use of any information
            obtained from the Service.`,
  },
  paragraph36: {
    id: 'pages.legal.terms.paragraph36',
    defaultMessage: `ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
            TO THESE TERMS OF SERVICE OR THE SERVICE MUST BE COMMENCED WITHIN
            ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH
            CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.`,
  },
  paragraph37: {
    id: 'pages.legal.terms.paragraph37',
    defaultMessage: `Please Read This Following Clause Carefully – It May Significantly
            Affect Your Legal Rights, Including Your Right to File a Lawsuit in
            Court`,
  },


  paragraph38P1: {
    id: 'pages.legal.terms.paragraph38P1',
    defaultMessage: 'Initial Dispute Resolution.'
  },
  paragraph38P2: {
    id: 'pages.legal.terms.paragraph38P2',
    defaultMessage: 'We are available by email at'
  },
  paragraph38P3: {
    id: 'pages.legal.terms.paragraph38P3',
    defaultMessage: `to address any concerns you may have regarding your use of the
            Service, these Terms of Service or our relationship with each other.
            Most concerns may be quickly resolved in this manner. Each of you
            and us agree to use best efforts to settle any dispute, claim,
            question, or disagreement directly through consultation and good
            faith negotiations which shall be a precondition to either party
            initiating a lawsuit or arbitration.`
  },
  paragraph39P1: {
    id: 'pages.legal.terms.paragraph39P1',
    defaultMessage: 'Agreement to Binding Arbitration.'
  },
  paragraph39P2: {
    id: 'pages.legal.terms.paragraph39P2',
    defaultMessage: `If we do not reach
            an agreed upon solution within a period of thirty (30) days from the
            time informal dispute resolution is pursued pursuant to the initial
            dispute resolution process above, then either party may initiate
            binding arbitration. All disputes, controversies or claims arising
            out of or relating to these Terms of Service (including their
            formation, performance and breach), the parties’ relationship with
            each other, including, without limitation, the relationship between
            you and any of our agents or employees, and/or your use of the
            Service, shall be finally settled by binding arbitration
            administered on a confidential basis by JAMS, in accordance with the
            JAMS Streamlined Arbitration Rules and Procedures, excluding any
            rules or procedures governing or permitting class actions. Each
            party will have the right to use legal counsel in connection with
            arbitration at its own expense. The parties shall select a single
            neutral arbitrator in accordance with the JAMS Streamlined
            Arbitration Rules and Procedures. The arbitrator, and not any
            federal, state or local court or agency, shall have exclusive
            authority to resolve all disputes arising out of or relating to the
            interpretation, applicability, enforceability or formation of these
            Terms of Service, including, but not limited to, any claim that all
            or any part of these Terms of Service is void or voidable. The
            arbitrator shall be empowered to grant whatever relief would be
            available in a court under law or in equity. The arbitrator’s award
            shall be in writing and provide a statement of the essential
            findings and conclusions, shall be binding on the parties and may be
            entered as a judgment in any court of competent jurisdiction. The
            interpretation and enforcement of these Terms of Service shall be
            subject to the Federal Arbitration Act.`
  },
  paragraph40P1: {
    id: 'pages.legal.terms.paragraph40P1',
    defaultMessage: 'The JAMS rules governing the arbitration may be accessed at'
  },
  paragraph40P2: {
    id: 'pages.legal.terms.paragraph40P2',
    defaultMessage: `. If you initiate arbitration, to the extent the filing fee for the
            arbitration exceeds Two Hundred and Fifty U.S. Dollars ($250.00), we
            will pay the additional cost. If we are required to pay the
            additional cost of the filing fees, you should submit a request for
            payment of fees to JAMS along with your form for initiating the
            arbitration, and we will make arrangements to pay all necessary fees
            directly to JAMS. We will also be responsible for paying all other
            arbitration costs arising in connection with the arbitration, other
            than costs incurred by you for legal counsel, travel and other
            out-of-pocket costs and expenses not constituting fees or amounts
            payable to JAMS. You will not be required to pay fees and costs
            incurred by us if you do not prevail in arbitration.`
  },
  paragraph41: {
    id: 'pages.legal.terms.paragraph41',
    defaultMessage: `The parties understand that, absent this mandatory provision, they
            would have the right to sue in court and have a jury trial. They
            further understand that, in some instances, the costs of arbitration
            could exceed the costs of litigation and the right to discovery may
            be more limited in arbitration than in court.`
  },



  paragraph42P1: {
    id: 'pages.legal.terms.paragraph42P1',
    defaultMessage: 'Class Action and Class Arbitration Waiver.'
  },
  paragraph42P2: {
    id: 'pages.legal.terms.paragraph42P2',
    defaultMessage: `You and us
            each further agree that any arbitration shall be conducted in our
            respective individual capacities only and not as a class action or
            other representative action, and you and us each expressly waive our
            respective right to file a class action or seek relief on a class
            basis. If any court or arbitrator determines that the class action
            waiver set forth in this paragraph is void or unenforceable for any
            reason or that an arbitration can proceed on a class basis, then the
            agreement to binding arbitration set forth above shall be deemed
            null and void in its entirety and the parties shall be deemed to
            have not agreed to arbitrate disputes.`
  },
  paragraph43P1: {
    id: 'pages.legal.terms.paragraph43P1',
    defaultMessage: 'Exception - Small Claims Court Claims.'
  },
  paragraph43P2: {
    id: 'pages.legal.terms.paragraph43P2',
    defaultMessage: `Notwithstanding the parties’ agreement to resolve all disputes
            through arbitration, either party may seek relief in a small claims
            court for disputes or claims within the scope of that court’s
            jurisdiction.`
  },
  paragraph44P1: {
    id: 'pages.legal.terms.paragraph44P1',
    defaultMessage: 'Exception – California Private Attorneys General Act (PAGA) Action.'
  },
  paragraph44P2: {
    id: 'pages.legal.terms.paragraph44P2',
    defaultMessage: `Notwithstanding the parties’ agreement to resolve all disputes
            through arbitration, either party may seek relief in a court of law
            for a claim arising under California’s Private Attorneys General
            Act.`
  },
  paragraph45P1: {
    id: 'pages.legal.terms.paragraph45P1',
    defaultMessage: '30 Day Right to Opt-Out.'
  },
  paragraph45P2: {
    id: 'pages.legal.terms.paragraph45P2',
    defaultMessage: `You have the right to
            opt-out and not be bound by the “Agreement to Binding Arbitration”
            and “Class Action and Class Arbitration Waiver” provisions set forth
            above by sending written notice of your decision to opt-out by
            emailing us at`
  },
  paragraph45P3: {
    id: 'pages.legal.terms.paragraph45P3',
    defaultMessage: `. The notice must be sent within thirty (30) days of registering to
            use the Service, otherwise you shall be bound to arbitrate disputes
            in accordance with the terms of those sections. If you opt-out of
            these arbitration provisions, we also will not be bound by them.`
  },
  paragraph46P1: {
    id: 'pages.legal.terms.paragraph46P1',
    defaultMessage: 'Exclusive Venue for Litigation.'
  },
  paragraph46P2: {
    id: 'pages.legal.terms.paragraph46P2',
    defaultMessage: `To the extent that
            the “Agreement to Binding Arbitration” provision does not apply or
            if you have opted out of arbitration, the parties agree that any
            litigation between them shall be filed exclusively in state or
            federal courts located in New York County, New York (except for
            small claims court actions which may be brought in the county where
            you reside). The parties expressly consent to exclusive jurisdiction
            in New York County, New York for any litigation other than small
            claims court actions.`
  },

  paragraph47: {
    id: 'pages.legal.terms.paragraph47',
    defaultMessage: `No waiver of these Terms of Service by the Company shall be deemed a
            further or continuing waiver of such term or condition or any other
            term or condition, and any failure of the Company to assert a right
            or provision under these Terms of Service shall not constitute a
            waiver of such right or provision.`
  },
  paragraph48: {
    id: 'pages.legal.terms.paragraph48',
    defaultMessage: `If any provision of these Terms of Service is held by a court of
            competent jurisdiction to be invalid, illegal or unenforceable for
            any reason, such provision shall be eliminated or limited to the
            minimum extent such that the remaining provisions of the Terms of
            Service will continue in full force and effect.`
  },
  paragraph49: {
    id: 'pages.legal.terms.paragraph49',
    defaultMessage: `The Terms constitute the sole and entire agreement between you and
            Unroll.Me with respect to the Service and supersede all prior and
            contemporaneous understandings, agreements, representations and
            warranties, both written and oral, with respect to the Service.`
  },
  paragraph50: {
    id: 'pages.legal.terms.paragraph50',
    defaultMessage: 'This Service is operated by Nielsen Consumer LLC, based in Chicago, IL.'
  },
  paragraph51P1: {
    id: 'pages.legal.terms.paragraph51P1',
    defaultMessage: 'All feedback, comments, requests for technical support and other communications relating to the Service should be directed to'
  },
  paragraph51P2: {
    id: 'pages.legal.terms.paragraph51P2',
    defaultMessage: 'our support team.'
  },

})

const track = (eventName, eventProps) => {
  trackEvent(eventName, {
    ...eventProps,
    Page: eventConstants.terms.pageName
  })
}

class Terms extends Component {
  componentDidMount () {
    track(eventConstants.terms.pageLoad)
  }

  render () {
    const { intl } = this.props
    const messages = Object.keys(messageDescriptors).reduce((messages, key) => {
      messages[key] = intl.formatMessage(messageDescriptors[key])
      return messages
    }, {})

    return (
      <Layout title={messages.title}>
        <Hero>
          <Section large>
            <Wrapper tc small>
              <Type variant="h1" f1_ns lh_copy dark_gray mb0>
                {messages.title}
              </Type>
            </Wrapper>
          </Section>
        </Hero>
        <Section large>
          <Wrapper small lh_copy>
            <P b>{messageDescriptors.paragraph1.defaultMessage}</P>
            <P mb4 pb4 bb b__light_gray>
              {messageDescriptors.paragraph2P1.defaultMessage}
              <a href="/">
                {messageDescriptors.unrollMeLabel.defaultMessage}
              </a>
              {messageDescriptors.paragraph2P2.defaultMessage}
              <a href="/">
                {messageDescriptors.unrollMeLabel.defaultMessage}
              </a>
              {messageDescriptors.paragraph2P3.defaultMessage}
            </P>
            <Subtitle underline>{messages.subtitle1}</Subtitle>
            <P>{messages.paragraph3}</P>
            <Subtitle mt4>{messages.subtitle2}</Subtitle>
            <P>{messages.paragraph4}</P>
            <Subtitle mt4>{messages.subtitle3}</Subtitle>
            <P>{messages.paragraph5}</P>
            <P>{messages.paragraph6}</P>
            <P>{messages.paragraph7}</P>
            <P>{messages.paragraph8}</P>
            <P>{messages.paragraph9}</P>
            <P>{messages.paragraph10}</P>
            <P>{messages.paragraph11}</P>
            <Subtitle mt4>{messages.subtitle4}</Subtitle>
            <P>{messages.paragraph12}</P>
            <P>{messages.paragraph13}</P>
            <BulletList ml2>
              <ListItem>{messages.list1Item1}</ListItem>
              <ListItem>{messages.list1Item2}</ListItem>
              <ListItem>{messages.list1Item3}</ListItem>
            </BulletList>
            <P mt2>{messages.paragraph14}</P>
            <BulletList ml2>
              <ListItem>{messages.list2Item1}</ListItem>
              <ListItem>{messages.list2Item2}</ListItem>
            </BulletList>
            <P mt2>{messages.paragraph15}</P>
            <P>{messages.paragraph16}</P>
            <Subtitle mt4>{messages.subtitle5}</Subtitle>
            <P>{messages.paragraph17}</P>
            <Subtitle mt4>{messages.subtitle6}</Subtitle>
            <P>{messages.paragraph18}</P>
            <BulletList ml2>
              <ListItem>{messages.list3Item1}</ListItem>
              <ListItem>{messages.list3Item2}</ListItem>
            </BulletList>
            <P mt2>{messages.paragraph19}</P>
            <BulletList ml2>
              <ListItem>{messages.list4Item1}</ListItem>
              <ListItem>{messages.list4Item2}</ListItem>
              <ListItem>{messages.list4Item3}</ListItem>
              <ListItem>{messages.list4Item4}</ListItem>
              <ListItem>{messages.list4Item5}</ListItem>
              <ListItem>{messages.list4Item6}</ListItem>
              <ListItem>{messages.list4Item7}</ListItem>
              <ListItem>{messages.list4Item8}</ListItem>
            </BulletList>
            <Subtitle mt4>{messages.subtitle7}</Subtitle>
            <P>{messages.paragraph20}</P>
            <Subtitle mt4>{messages.subtitle8}</Subtitle>
            <P>{messages.paragraph21}</P>
            <Subtitle mt4>{messages.subtitle9}</Subtitle>
            <P>{messages.paragraph22}</P>
            <Subtitle mt4>{messages.subtitle10}</Subtitle>
            <P>{messages.paragraph23}</P>
            <P>{messages.paragraph24}</P>
            <P>{messages.paragraph25}</P>
            <P>{messages.paragraph26}</P>
            <Subtitle mt4>{messages.subtitle11}</Subtitle>
            <P>{messages.paragraph27}</P>

            <Subtitle mt4>{messages.subtitle12}</Subtitle>
            <P>{messages.paragraph28}</P>

            <P>{messages.paragraph29}</P>

            <P>{messages.paragraph30}</P>

            <P>{messages.paragraph31}</P>

            <P>{messages.paragraph32}</P>

            <Subtitle mt4>{messages.subtitle13}</Subtitle>
            <P>{messages.paragraph33}</P>

            <P>{messages.paragraph34}</P>

            <Subtitle mt4>{messages.subtitle14}</Subtitle>
            <P>{messages.paragraph35}</P>

            <Subtitle mt4>{messages.subtitle15}</Subtitle>
            <P>{messages.paragraph36}</P>

            <Subtitle mt4>{messages.subtitle16}</Subtitle>
            <P b underline>
              {messages.paragraph37}
            </P>

            <P>
              <Span b>{messages.paragraph38P1}</Span>{' '}
              {messages.paragraph38P2}{' '}
              <a href="mailto:support@unroll.me?subject=Terms of Service" onClick={() => {
                track(eventConstants.common.supportLinkClicked, {
                  [eventConstants.common.supportType.propName]: eventConstants.common.supportType.email,
                  [eventConstants.common.supportCta.propName]: eventConstants.common.supportCta.email
                })
              }}>
                support@unroll.me
              </a>{' '}
              {messages.paragraph38P3}
            </P>
            <P>
              <Span b>{messages.paragraph39P1}{' '}</Span>{messages.paragraph39P2}
            </P>
            <P b>
              {messages.paragraph40P1}{' '}
              <A
                href="https://www.jamsadr.com/adr-rules-procedures"
                target="_blank"
                onClick={() => {
                  track(eventConstants.terms.adrRulesLinkClicked)
                }}
              >{' '}
                https://www.jamsadr.com/adr-rules-procedures
              </A>
              {messages.paragraph40P2}
            </P>
            <P>
              {messages.paragraph41}
            </P>
            <P>
              <Span b>{messages.paragraph42P1}{' '}</Span>{messages.paragraph42P2}
            </P>
            <P>
              <Span b>{messages.paragraph43P1}</Span>{' '}
              {messages.paragraph43P2}
            </P>
            <P>
              <Span b>
                {messages.paragraph44P1}
              </Span>{' '}
              {messages.paragraph44P2}
            </P>
            <P>
              <Span b>{messages.paragraph45P1}</Span>{messages.paragraph45P2}{' '}
              <a href="mailto:support@unroll.me?subject=Terms of Service">
                support@unroll.me
              </a>
              {messages.paragraph45P3}
            </P>
            <P>
              <Span b>{messages.paragraph46P1}</Span> {messages.paragraph46P2}
            </P>

            <Subtitle mt4>{messages.subtitle17}</Subtitle>
            <P>
              {messages.paragraph47}
            </P>
            <P>
              {messages.paragraph48}
            </P>

            <Subtitle mt4>{messages.subtitle18}</Subtitle>
            <P>
              {messages.paragraph49}
            </P>

            <Subtitle mt4>{messages.subtitle19}</Subtitle>
            <P>{messageDescriptors.paragraph50.defaultMessage}</P>

            <P>
              {messages.paragraph51P1}{' '}
              <a
                href="https://unrollme.zendesk.com/hc/en-us/requests/new" onClick={() => {
                track(eventConstants.common.supportLinkClicked, {
                  [eventConstants.common.supportType.propName]: eventConstants.common.supportType.zendesk,
                  [eventConstants.common.supportCta.propName]: eventConstants.common.supportCta.zendesk
                })
              }}>
                {messages.paragraph51P2}
              </a>
            </P>
          </Wrapper>
        </Section>
      </Layout>
    )
  }
}

export default injectIntl(Terms)
